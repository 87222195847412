import { AuthType } from '@helpers/AuthClient';
import ResetPasswordView from '@views/ResetPasswordView';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';

const ResetPasswordPage: FC<PageProps> = () => {
  return <ResetPasswordView authType={AuthType.Web} />;
};

export default ResetPasswordPage;
